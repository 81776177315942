/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-09-09 10:25:28
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-09-10 10:17:55
 * @FilePath: src/app/(main)/_user_components/role-badge .tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */

import React from "react";
import { twMerge } from "tailwind-merge";
import { RoleEnum } from "@/TS/Enums/global_enums";
import { RoleBadgeProps } from "@/TS/Interfaces/global_interfaces";
import { ColorValueHex, RoleType } from "@/TS/Types/global_types";
function RoleBadge({
  role
}: RoleBadgeProps) {
  const getRoleBadgeColor = (role: RoleType): ColorValueHex => {
    switch (role) {
      case "member":
        return "#009EF1";
      case "president":
        return "#EE4540";
      default:
        return "#EE4540";
    }
  };
  return <div style={{
    backgroundColor: getRoleBadgeColor(role)
  }} className={twMerge("md:py-2 md:px-5 py-1 px-2.5 md:text-base text-sm text-center text-white rounded-full bg-primary")} data-sentry-component="RoleBadge" data-sentry-source-file="role-badge .tsx">
      <p>{RoleEnum[role.toUpperCase()]}</p>
    </div>;
}
export default RoleBadge;