import React, { useEffect, useState } from "react";
import Accordion from "@/app/(main)/_user_components/accordion";
import ContractItem from "@/app/(main)/_user_components/contract-item";
import RoleBadge from "@/app/(main)/_user_components/role-badge ";
import { getMyInvoices } from "@/app/api/dynabuy-back/api-profile";
import ComponentLoading from "@/components/global/component-loading";
import { HydraEnum } from "@/TS/Enums/global_enums";
function UserFacture(props) {
  const [clubList, setClubList] = useState([]);
  useEffect(() => {
    const fetchInvoices = async () => {
      const invoicesApi = await getMyInvoices();
      const clubList = {};
      invoicesApi[HydraEnum.LIST].forEach(({
        clubName,
        isPresident,
        invoiceName,
        size,
        createdAt,
        url,
        id
      }) => {
        const key = clubName + "_" + isPresident;
        const value = {
          isPresident,
          invoiceName,
          size,
          createdAt,
          url,
          id
        };
        if (!clubList[key]) {
          clubList[key] = [value];
        } else {
          clubList[key].push(value);
        }
      });
      setClubList(clubList);
    };
    fetchInvoices();
  }, []);
  return <>
      <div className="flex flex-col  items-center justify-center">
        <h2 className={"mx-auto text-xl md:text-3xl mt-1 md:mt-11"}>
          Mes factures
        </h2>
        <p className={"test-sm italic mb-10 md:mb-14 text-[16px] md:text-left text-center"}>
          Retrouvez ici l’ensemble de vos factures éditées et classées selon
          l’appartenance à votre Club d’Affaires.
        </p>
      </div>

      {!clubList ? <ComponentLoading></ComponentLoading> : <div className="flex flex-col gap-4">
          {Object.entries(clubList).map(([key, value], index) => {
        const title = key.split("_")[0];
        const isPresident = key.split("_")[1] === "false" ? false : Boolean(key.split("_")[1]);
        return <>
                <Accordion key={key} initialToggle={index === 0 ? true : false} droppedJsx={value.map(({
            isPresident,
            invoiceName,
            size,
            createdAt,
            url,
            id
          }, index) => {
            return <ContractItem key={id} index={index} infos={{
              isPresident,
              invoiceName,
              size,
              createdAt,
              url,
              id
            }}></ContractItem>;
          })}>
                  <div className="flex flex-col gap-0 md:gap-4 md:items-center items-start md:flex-row ">
                    <h3 className={"text-[20px] mb-0 text-secondary uppercase"}>
                      {title}
                    </h3>
                    <RoleBadge role={isPresident ? "president" : "member"}></RoleBadge>
                  </div>
                </Accordion>
              </>;
      })}
        </div>}
    </>;
}
export default UserFacture;