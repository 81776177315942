/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-09-06 15:08:49
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-09-11 16:07:28
 * @FilePath: src/app/(main)/_user_components/user-contracts.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */

import React, { useEffect, useMemo, useState } from "react";
import Accordion from "@/app/(main)/_user_components/accordion";
import ContractItem from "@/app/(main)/_user_components/contract-item";
import RoleBadge from "@/app/(main)/_user_components/role-badge ";
import { getMyContracts } from "@/app/api/dynabuy-back/api-profile";
import ComponentLoading from "@/components/global/component-loading";
import { HydraEnum } from "@/TS/Enums/global_enums";
function UserContracts(props) {
  const [clubList, setClubList] = useState([]);
  useEffect(() => {
    const fetchInvoices = async () => {
      const invoicesApi = await getMyContracts();
      const clubList = {};
      invoicesApi[HydraEnum.LIST].forEach(({
        clubName,
        isPresident,
        invoiceName,
        size,
        createdAt,
        url,
        id
      }) => {
        const key = clubName + "_" + isPresident;
        const value = {
          isPresident,
          invoiceName,
          size,
          createdAt,
          url,
          id
        };
        if (!clubList[key]) {
          clubList[key] = [value];
        } else {
          clubList[key].push(value);
        }
      });
      setClubList(clubList);
    };
    fetchInvoices();
  }, []);
  return <>
      <div className="flex flex-col  items-center justify-center">
        <h2 className={"mx-auto text-xl md:text-3xl mt-1 md:mt-11"}>
          Mes contrats et avenants
        </h2>
        <p className={"test-sm italic mb-10 md:mb-14 text-[16px] md:text-left text-center"}>
          Retrouvez ici l’ensemble de vos contrats et avenants édités et classés
          selon l’appartenance à votre Club d’Affaires.
        </p>
      </div>

      {!clubList ? <ComponentLoading></ComponentLoading> : <div className="flex flex-col gap-4">
          {Object.entries(clubList).map(([key, value], index) => {
        const title = key.split("_")[0];
        const isPresident = key.split("_")[1] === "false" ? false : Boolean(key.split("_")[1]);
        return <Accordion key={key} initialToggle={index === 0 ? true : false} droppedJsx={value.map(({
          isPresident,
          contractName,
          size,
          createdAt,
          url,
          id
        }, index) => {
          return <ContractItem key={id} index={index} infos={{
            isPresident,
            contractName,
            size,
            createdAt,
            url,
            id
          }}></ContractItem>;
        })}>
                <div className="flex flex-col gap-0 md:gap-4 md:items-center items-start md:flex-row ">
                  <h3 className={"text-[20px] mb-0 text-secondary uppercase"}>
                    {title}
                  </h3>
                  <RoleBadge role={isPresident ? "president" : "member"}></RoleBadge>
                </div>
              </Accordion>;
      })}
        </div>}
    </>;
}
export default UserContracts;