"use client";

/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-10-21 10:10:56
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-10-21 11:27:59
 * @FilePath: src/utils/close-mobile-nav-when-path-change.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import { usePathname } from "next/navigation";
import React, { useContext, useEffect } from "react";
import { isBurgerActiveContext } from "@/context/is-burger-active";
function CloseMobileNavWhenPathChange() {
  const [_, setIsActive] = useContext(isBurgerActiveContext);
  const pathname = usePathname();
  useEffect(() => {
    setIsActive(false);
    if (typeof window === "undefined") return;
    document.body.style.overflow = "visible";
  }, [pathname]);
  return undefined;
}
export default CloseMobileNavWhenPathChange;