/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-09-06 15:16:46
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-09-10 10:19:43
 * @FilePath: src/app/(main)/_user_components/accordion.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */

import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Icon from "@/components/icon";
function Accordion({
  children,
  initialToggle,
  droppedJsx
}) {
  const [isToggled, setIsToggled] = useState<Readonly<boolean>>(false);
  useEffect(() => {
    // 🐱‍👤 Using useEffect to display the animation on load
    if (!droppedJsx) return;
    setIsToggled(initialToggle);
  }, [initialToggle, droppedJsx]);
  return <>
      <div className={"ring-1 h-fit box-border ring-grey rounded px-6 py-5 cursor-pointer"} onClick={e => {
      setIsToggled(prev => !prev);
    }}>
        <div className={"flex  gap-6 items-center"}>
          {children}
          <Icon size={18} icon={"chevron-right"} style={isToggled ? {
          transform: "rotateX(180deg) rotateZ(90deg)"
        } : {
          transform: "rotateX(0deg) rotateZ(90deg)"
        }} className={"rotate-90 self-start md:self-center transition-all ml-auto duration-300"} data-sentry-element="Icon" data-sentry-source-file="accordion.tsx"></Icon>
        </div>
        <AnimatePresence initial={false} data-sentry-element="AnimatePresence" data-sentry-source-file="accordion.tsx">
          {isToggled && <motion.div className={"bloc overflow-hidden box-border"} initial={{
          height: 0
        }} animate={{
          height: "auto"
        }} exit={{
          height: 0
        }} transition={{
          type: "spring",
          duration: 0.4,
          bounce: 0
        }}>
              <motion.div initial={{
            opacity: 0
          }} animate={{
            opacity: 1
          }} exit={{
            opacity: 0
          }} className={"flex gap-2 mt-6 flex-col"}>
                {droppedJsx}
              </motion.div>
            </motion.div>}
        </AnimatePresence>
      </div>
    </>;
}
export default Accordion;