"use client";

import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getUserContactTopics, postUserContact } from "@/app/api/dynabuy-back/api-contact";
import Button from "@/components/button";
import FormPartTitle from "@/components/form/form-part-title";
import ClientFlash from "@/components/global/client-flash";
import ComponentLoading from "@/components/global/component-loading";
import SelectInput from "@/components/global/inputs/select-input";
import TextAreaInput from "@/components/global/inputs/textarea-input";
import useSubmit from "@/hooks/use-submit";
import { ContactTopicRead } from "@/TS/Interfaces/global_interfaces";
import { handleErrors } from "@/utils/formFunc/form-func";
export default function UserContact() {
  const {
    register,
    reset,
    unregister,
    setValue,
    handleSubmit
  } = useForm();
  const {
    errors,
    loading,
    onSubmit,
    setVal
  } = useSubmit(postUserContact, {
    callbackMessage: {
      message: "Votre demande de rappel a bien été prise en compte et sera traitée dans les meilleurs délais.",
      type: "success2",
      position: "global"
    },
    sidebarJsx: "",
    errorFlashPath: "/mes-clubs#contact"
  });
  const [contactTopics, setContactTopics] = useState(undefined);
  useEffect(() => {
    const fetchContactTopics = async () => {
      setContactTopics(await getUserContactTopics());
    };
    fetchContactTopics();
  }, []);
  if (!contactTopics) return <ComponentLoading></ComponentLoading>;
  return <div className={"flex flex-col"} data-sentry-component="UserContact" data-sentry-source-file="user-contact.tsx">
      <h4 className={"text-xl font-bold my-6 text-center"}>Aide & contact</h4>
      <p className={"text-center mb-6 italic"}>
        Notre équipe est à votre disposition du lundi au vendredi, de 9h à 12h30
        et de 14h à 17h30.
      </p>
      <form onSubmit={handleSubmit(data => {
      onSubmit(data);
    })} className="flex w-full flex-col">
        <FormPartTitle data-sentry-element="FormPartTitle" data-sentry-source-file="user-contact.tsx">MOTIF</FormPartTitle>
        <div className={"flex mb-14"}>
          <SelectInput name="topic" id="topic" value={""} register={register} required={true} error={handleErrors(errors, "topic")} classNameDivider={"w-fit"} data-sentry-element="SelectInput" data-sentry-source-file="user-contact.tsx">
            <option value="" disabled>
              Sélectionner un motif de demande de rappel
            </option>
            {contactTopics.map((topic: ContactTopicRead, index: number) => <option value={topic["@id"]} key={index}>
                {topic.label}
              </option>)}
          </SelectInput>
        </div>
        <FormPartTitle data-sentry-element="FormPartTitle" data-sentry-source-file="user-contact.tsx">DEMANDE DE RAPPEL</FormPartTitle>
        <div className={"flex mb-6"}>
          <TextAreaInput rows={5} maxLength={1000} loading={loading} error={handleErrors(errors, "message")} register={register} placeholder={"Bonjour, je souhaiterais être rappelé(e) entre ...h et ...h"} name={"message"} id={"message"} classNameTextarea={"resize-none"} data-sentry-element="TextAreaInput" data-sentry-source-file="user-contact.tsx"></TextAreaInput>
        </div>
        <ClientFlash path={"/mes-clubs#contact"} data-sentry-element="ClientFlash" data-sentry-source-file="user-contact.tsx" />
        <div className={"flex flex-col mb-12"}>
          <div className={"flex justify-center"}>
            <Button type={"button"} variant={"unfilled"} colorTheme={"secondary"} className={"my-6 w-44 mx-2"} onClick={() => {
            setVal("");
          }} data-sentry-element="Button" data-sentry-source-file="user-contact.tsx">
              Annuler
            </Button>
            <Button type={"submit"} className={"my-6 w-44 mx-2"} data-sentry-element="Button" data-sentry-source-file="user-contact.tsx">
              {/*TODO Icon*/}
              Rappelez-moi
            </Button>
          </div>
        </div>
        <div>
          <p className={"mb-4"}>
            Lorsque vous nous contactez, les informations que vous communiquez à
            DYNABUY ne sont utilisées qu’aux fins de gestion de votre demande,
            sur la base de l’intérêt légitime de DYNABUY à répondre aux demandes
            de contact qui nous sont adressées.
          </p>
          <p className={"mb-4"}>
            DYNABUY ne conserve les données à caractère personnel traitées que
            pour la durée nécessaire aux opérations pour lesquelles elles ont
            été collectées ainsi que dans le respect de la réglementation en
            vigueur. Pour tout complément d’information, vous pouvez consulter
            notre page{" "}
            <Link className={"text-primary underline"} target={"_blank"} href="/" data-sentry-element="Link" data-sentry-source-file="user-contact.tsx">
              politique de confidentialité
            </Link>
            .
          </p>
          <p className={"mb-4"}>
            Les droits d’accès, de rectification, d’opposition, d’effacement, à
            la portabilité, à la limitation du traitement peuvent être exercés
            par courriel à l’adresse{" "}
            <Link className={"text-primary underline"} href="mailto:dpo@dynabuy.fr" data-sentry-element="Link" data-sentry-source-file="user-contact.tsx">
              dpo@dynabuy.fr
            </Link>
          </p>
          <p>
            DYNABUY apporte une grande attention aux données à caractère
            personnel qui lui sont confiées. Si vous considérez que le
            traitement des données vous concernant porte atteinte à vos droits,
            vous disposez de la faculté d’introduire une réclamation auprès de
            la CNIL.
          </p>
        </div>
      </form>
    </div>;
}