"use client";

import { twMerge } from "tailwind-merge";
import { FormPartTitleProps } from "@/TS/Interfaces/global_interfaces";
export default function FormPartTitle(props: FormPartTitleProps) {
  return <div className={twMerge(`flex items-center font-medium ${props.className}`)} data-sentry-component="FormPartTitle" data-sentry-source-file="form-part-title.tsx">
      <h3 className={twMerge("mb-0", props.classNameTitle)}>
        {props.children}
      </h3>
      <hr className={"ml-3 grow text-transparent bg-light-grey h-0.5"} />
    </div>;
}