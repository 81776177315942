"use client";

/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-09-09 12:37:53
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-10-04 15:02:57
 * @FilePath: src/app/(main)/_user_components/contract-item.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Dropdown from "@/app/(main)/(club)/mes-clubs/[id]/leads/components/dropdown";
import { changeDateFormat } from "@/app/(main)/(club)/mes-clubs/[id]/utils/utils";
import Icon from "@/components/icon";
import useMobile from "@/hooks/use-mobile";
function humanFileSize(bytes, si: boolean = false, dp: number = 1): string {
  const thresh: 1000 | 1024 = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " Ko";
  }
  const units: Array<string> = si ? ["Mo", "Go", "To", "Po"] : ["MiB", "GiB", "TiB", "PiB"];
  let u: number = -1;
  const r: number = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + " " + units[u];
}
function ContractItem({
  index,
  infos
}) {
  const isMobile: boolean = useMobile();
  const pdfUrl = infos.url;
  const openPdf = (): void => {
    window.open(pdfUrl, "_blank", "noopener,noreferrer");
  };

  // const downloadPdf = () => {
  //   fetch("https://cryptic-headland-94862.herokuapp.com/" + infos.url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/pdf",
  //     },
  //   })
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       console.log(blob);
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `FileName.pdf`);
  //
  //       document.body.appendChild(link);
  //
  //       link.click();
  //
  //       link.parentNode.removeChild(link);
  //     });
  // };

  return <motion.div initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} transition={{
    delay: index * 0.1
  }} className={"flex justify-between bg-info text-sm py-5 px-4 md:px-6"} data-sentry-element="unknown" data-sentry-component="ContractItem" data-sentry-source-file="contract-item.tsx">
      <motion.div initial={{
      opacity: 0,
      y: 15
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      delay: index * 0.15
    }} className="flex items-center gap-4" data-sentry-element="unknown" data-sentry-source-file="contract-item.tsx">
        <Image alt={"icon of an pdf image"} width={25} height={25} src={"/icons/picto-pdf-download.svg"} data-sentry-element="Image" data-sentry-source-file="contract-item.tsx"></Image>
        <div className="flex flex-col">
          <p className={"text-sm"}>{infos.contractName}</p>
          {isMobile && <>
              <p>{humanFileSize(infos.size, true)}</p>
              {new Date(infos.createdAt).toLocaleDateString() + " - " + new Date(infos.createdAt).toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit"
          }).replace(":", "h")}
            </>}
        </div>
      </motion.div>
      <motion.div initial={{
      opacity: 0,
      x: 8
    }} animate={{
      opacity: 1,
      x: 0
    }} transition={{
      delay: index * 0.15
    }} className="flex gap-6 items-center" data-sentry-element="unknown" data-sentry-source-file="contract-item.tsx">
        {!isMobile && <>
            <p>{humanFileSize(infos.size, true)}</p>
            {new Date(infos.createdAt).toLocaleDateString() + " - " + new Date(infos.createdAt).toLocaleTimeString("fr-FR", {
          hour: "2-digit",
          minute: "2-digit"
        }).replace(":", "h")}
          </>}
        <Dropdown additionalX={document.body.clientWidth} additionalY={-45} jsx={<>
              {/*<p onClick={openPdf}>Ouvrir le PDF</p>*/}
              <button className={"px-3"} onClick={openPdf}>
                Consulter
              </button>
            </>} data-sentry-element="Dropdown" data-sentry-source-file="contract-item.tsx">
          <div className={"flex p-2 items-center justify-center"}>
            <Icon className={"z-50"} icon={"dots"} size={20} data-sentry-element="Icon" data-sentry-source-file="contract-item.tsx"></Icon>
          </div>
        </Dropdown>
      </motion.div>
    </motion.div>;
}
export default ContractItem;