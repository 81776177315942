"use client";

import Link from "next/link";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/icon";
import useMobile from "@/hooks/use-mobile";
import { SidebarItemProps } from "@/TS/Interfaces/global_interfaces";
export default function SidebarItem(props: SidebarItemProps) {
  const isMobile = useMobile();
  let classes = twMerge(`w-full flex items-center justify-start hover:font-bold gap-2 md:text-md hover:text-primary ${props.className}`);
  return <>
      {props.href ? <Link href={props.href} className={classes} onClick={props.onClick} target={props.targetBlanck ? "_blank" : undefined}>
          <Icon icon={props.icon} size={isMobile ? 25 : 20} className={"mr-2"}></Icon>
          <p className={" text-lg md:text-base"}>{props.title}</p>
        </Link> : <div className={classes} onClick={props.onClick}>
          <Icon icon={props.icon} size={isMobile ? 25 : 20} className={"mr-2"}></Icon>
          <p className={" text-lg md:text-base"}>{props.title}</p>
        </div>}
    </>;
}